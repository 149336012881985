import createClient, { ClientInstance } from './clients/AxiosClient';
import { App as VueApp, inject } from 'vue';

import { UserRepository } from './UserRepository';
import { ServiceRepository } from './ServiceRepository';
import {ChatRepository} from "@/services/repositories/ChatRepository";

const HTTP_CLIENT_PROVIDE_KEY = 'httpClient';
const REPOSITORIES_PROVIDE_KEY = 'repositories';

export type Repositories = ReturnType<typeof createRepositories>;
export * from './clients/AxiosClient';
export type { ClientInstance };

export function createRepositories(client: ClientInstance) {
  return {
    user: new UserRepository(client),
    service: new ServiceRepository(client),
    chats: new ChatRepository(client),
  };
}

export interface AppRepositoriesConfig {
  baseUrl: string;
}

export const AppRepositories = {
  install(app: VueApp, config: AppRepositoriesConfig) {
    if (!config || !config.baseUrl) {
      throw new Error('Не передан baseUrl для инициализации http-клиента');
    }

    const client = createClient(config.baseUrl);
    const repositories = createRepositories(client);

    app.config.globalProperties.$repositories = repositories;
    app.config.globalProperties.$http = client;

    app.provide(REPOSITORIES_PROVIDE_KEY, repositories);
    app.provide(HTTP_CLIENT_PROVIDE_KEY, repositories);
  }
};

/**
 * Получение списка репозиториев, для работы с Api
 *
 * @returns
 */
export function useRepositories(): Repositories {
  const repositories = inject(REPOSITORIES_PROVIDE_KEY) as Repositories|undefined;

  if (!repositories) {
    throw new Error('Для получения репозиториев необходимо их инициализировать');
  }

  return repositories;
}

/**
 * Получение http-клиента, для работы с Api
 *
 * @returns
 */
export function useHttpClient(): ClientInstance {
  const client = inject(HTTP_CLIENT_PROVIDE_KEY) as ClientInstance|undefined;

  if (!client) {
    throw new Error('Для получения http-клиента его необходимо инициализировать');
  }

  return client;
}
