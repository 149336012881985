import {CarData, UserData} from "@/services/repositories/models/User";
import {computed, ComputedRef, reactive, UnwrapRef} from "vue";
import {BaseStore, BaseStoreContext} from "@/services/stores/BaseStore";
import {isClientError} from "@/services/repositories";
import {CreateVisitDto, Visit} from "@/services/repositories/models/visit";
import {Chats, SendingMessage} from "@/services/repositories/models/Chat";

export interface PointStoreState {
  current: UserData|null;
  cars: CarData[]|null;
  visits: Visit[]|null;
  chats: Chats[]|null;
  currentVisit: Visit|null;
}

export class UserStore extends  BaseStore {
  readonly state: UnwrapRef<PointStoreState>;
  readonly user: ComputedRef<UserData|null>;
  readonly cars: ComputedRef<CarData[]|null>
  readonly visits: ComputedRef<Visit[]|null>
  readonly chats: ComputedRef<Chats[]|null>
  readonly currentVisit: ComputedRef<Visit|null>


  constructor(ctx: BaseStoreContext) {
    super(ctx);

    this.state = reactive({
      current: null,
      cars: null,
      visits: null,
      chats: null,
      currentVisit: null,
    });

    this.user = computed(() => this.state.current);
    this.cars = computed(() => this.state.cars);
    this.visits = computed(() => this.state.visits);
    this.chats = computed(() => this.state.chats);
    this.currentVisit = computed(() => this.state.currentVisit);
  }

  async getCurrentUser() {
    const { data } = await this.repositories.user.getCurrentUser()

    try {
      this.state.current = data.data;
    } catch (e) {
      if (isClientError(e)) {
        const status = e.response?.status || 0;

        if (status >= 400 && status < 500) {
          localStorage.removeItem('token');
        }
      }

      throw e;
    }
  }

  async getUserCars() {
    const { data } = await this.repositories.user.getUserCars();

    try {
      this.state.cars = data.data;
    } catch (e) {
      if (isClientError(e)) {
        const status = e.response?.status || 0;

        if (status >= 400 && status < 500) {
          localStorage.removeItem('token');
        }
      }

      throw e;
    }
  }

  async getUserVisits() {
    const { data } = await this.repositories.user.getUserVisits();

    try {
      this.state.visits = data.data;
    } catch (e) {
      if (isClientError(e)) {
        const status = e.response?.status || 0;

        if (status >= 400 && status < 500) {
          localStorage.removeItem('token');
        }
      }

      throw e;
    }
  }

  async getVisitById(visitId: string) {
    const { data } = await this.repositories.user.getVisitById(visitId);

    try {
      this.state.currentVisit = data.data[0];
    } catch (e) {
      if (isClientError(e)) {
        const status = e.response?.status || 0;

        if (status >= 400 && status < 500) {
          localStorage.removeItem('token');
        }
      }

      throw e;
    }
  }

  async createVisit (payload: CreateVisitDto) {
    const { data } = await this.repositories.user.createVisit(payload);

    try {
      this.state.currentVisit = data.data;

      return data;
    } catch (e) {
      if (isClientError(e)) {
        const status = e.response?.status || 0;

        if (status >= 400 && status < 500) {
          localStorage.removeItem('token');
        }
      }

      throw e;
    }
  }

  async getUserChats() {
    const { data } = await this.repositories.chats.getUserChats();

    try {
      this.state.chats = data.data;
    } catch (e) {
      if (isClientError(e)) {
        const status = e.response?.status || 0;

        if (status >= 400 && status < 500) {
          localStorage.removeItem('token');
        }
      }

      throw e;
    }
  }

  async getChatUpdates(chatId: string) {
    const { data } = await this.repositories.chats.getChatUpdates(chatId);

    try {
      return data;
    } catch (e) {
      if (isClientError(e)) {
        const status = e.response?.status || 0;

        if (status >= 400 && status < 500) {
          localStorage.removeItem('token');
        }
      }

      throw e;
    }
  }

  async getChatMessages(chatId: string) {
    const { data } = await this.repositories.chats.getChatMessages(chatId);

    try {
      return data.data;
    } catch (e) {
      if (isClientError(e)) {
        const status = e.response?.status || 0;

        if (status >= 400 && status < 500) {
          localStorage.removeItem('token');
        }
      }

      throw e;
    }
  }

  async sendMessages(chatId: string, payload: SendingMessage) {
    const { data } = await this.repositories.chats.sendMessage(chatId, payload);

    try {
      return data.data;
    } catch (e) {
      if (isClientError(e)) {
        const status = e.response?.status || 0;

        if (status >= 400 && status < 500) {
          localStorage.removeItem('token');
        }
      }

      throw e;
    }
  }
}
