import {ClientInstance, Repositories} from "@/services/repositories";

export interface BaseStoreContext {
  repositories: Repositories;
  http: ClientInstance;
}

export abstract class BaseStore<StoreContext extends BaseStoreContext = BaseStoreContext> {
  protected ctx: StoreContext;

  constructor(ctx: StoreContext) {
    this.ctx = ctx;
  }

  get repositories() {
    return this.ctx.repositories;
  }
}
