<script lang="ts" setup>
import { defineProps } from 'vue'

const props = defineProps({
  /** Скорректирвоать вертикальные отступы (visual-fix) */
  correctV: {
    type: Boolean,
    default: true,
  }
});

</script>

<template>
  <div class="c-title">
    <div :class="{
      'c-title__inner-wrap': true,
      'correct-text-block-indents-v': props.correctV
    }">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
.c-title {
  --line-height: 1.1em;

  font-size: var(--c12c-text-size-lg, 1.1rem);
  line-height: var(--line-height);
  font-weight: normal;
}
</style>