<script lang="ts" setup>
import {computed} from 'vue';
import { useRouter } from 'vue-router';
import {useStore} from "@/composables/useApp";

const router = useRouter();
const store = useStore();

const user = computed(() => (store.user.state.current))

const userName = computed(() => {
  if (user.value) {
    return user.value?.firstName;
  }

  return 'Пользователь'
})

function onClickUserAccount() {
  router.push({ name: 'account-main' });
}
</script>

<template>
  <ion-button class="user-heaeder-button" @click="onClickUserAccount">
    {{ userName }}
    <ion-icon name="c12c-user-circle"></ion-icon>
  </ion-button>
</template>

<style lang="scss">
.user-heaeder-button {
  --color: var(--ion-text-color);

  &.button.in-toolbar {
    --padding-start: var(--ion-padding);
    --padding-end: var(--ion-padding);

    font-weight: 500;
    margin: 0;
  }

  ion-icon {
    width: 28px;
    height: 28px;
    margin-left: 4px;
  }
}
</style>
