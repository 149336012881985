<script lang="ts" setup>
import {defineProps, withDefaults, onBeforeMount} from 'vue';
import OrgShortInfo from '@/components/org/OrgShortInfo.vue';
import UserHeaderButton from '@/components/user/UserHeaderButton.vue';
import {useStore} from "@/composables/useApp";


export interface IProps {
  defaultHref?: string;
  showTitle?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  showTitle: true
});
const store = useStore();

onBeforeMount(() => {
  store.user.getCurrentUser();
  store.user.getUserCars();
  store.user.getUserVisits();
})

</script>

<template>
  <ion-header class="c-app-header">
    <ion-toolbar class="ion-no-padding">
      <div slot="start">
        <slot name="left">
          <org-short-info class="ion-padding" />
        </slot>
      </div>

      <div slot="end">
        <slot name="right">
          <ion-buttons>
            <user-header-button />
          </ion-buttons>
        </slot>
      </div>
    </ion-toolbar>

    <ion-toolbar v-if="showTitle" class="ion-no-padding">
      <div class="g-header-title c-app-header__title">
        <ion-back-button
          class="c-app-header__back-button"
          :default-href="props.defaultHref"
        />
        <slot></slot>
      </div>
    </ion-toolbar>
  </ion-header>
</template>

<style lang="scss">
.c-app-header {
  &__title {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0 var(--ion-padding) calc(var(--ion-padding) - var(--c12c-font-correct-bottom, 0));
  }

  &__back-button {
    margin: -4px 0;

    &::part(icon) {
      --icon-margin-start: -4px;
      --icon-margin-end: 10px;
      --icon-font-size: var(--c12c-text-size-lg);
    }

    &::part(native)::after {
      left: calc(var(--ion-padding) * -1);
      right: -50px;
      bottom: calc(var(--ion-padding) * -1);
    }
  }
}
</style>
