import { createPinia } from 'pinia';
import {UserStore} from "@/services/stores/UserStore";
import {ClientInstance, Repositories} from "@/services/repositories";
import {ServiceStore} from "@/services/stores/ServiceStore";

const pinia = createPinia();

export { pinia };

export interface StoreInstance {
  recreateInstances: () => StoreInstance;
  user: UserStore;
  service: ServiceStore;
}

export function createStore(repositories: Repositories, http: ClientInstance): StoreInstance {
  const instances: Partial<StoreInstance> = {
    recreateInstances,
  };

  function recreateInstances(): StoreInstance {
    const ctx = { repositories, http };

    const user = new UserStore(ctx);
    const service = new ServiceStore(ctx);

    Object.assign(instances, {
      user,
      service
    });

    return instances as StoreInstance;
  }

  return recreateInstances();
}
