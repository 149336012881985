import { BaseRepository, AsyncResponse } from './BaseRepository';

export class ServiceRepository extends BaseRepository {
  getServicesTypes(withCategories: boolean): AsyncResponse<any> {
    return this.client.get(`/telegram/services/types?withCategories=${withCategories}`, {
      headers: {
        'X-AppClientId': 872
      }
    });
  }

  getCategoryById (categoryId: number) {
    return this.client.get(`/telegram/services?pointTypeId=${categoryId}`, {
      headers: {
        'X-AppClientId': 872
      }
    });
  }
}
