import {AsyncResponse, BaseRepository } from './BaseRepository';
import {Car, User} from "@/services/repositories/models/User";
import {CreateVisitDto, Visit} from "@/services/repositories/models/visit";

export class UserRepository extends BaseRepository {
  /**
   * Получение текущего пользователя
   *
   * @returns
   */
  getCurrentUser(): AsyncResponse<User> {
    return this.client.get('/telegram/user', {
      headers: {
        'X-AppClientId': 872
      }
    });
  }

  /**
   * Получение списка автомобилей пользователя
   *
   * @returns
   */
  getUserCars(): AsyncResponse<Car> {
    return this.client.get('/telegram/user/cars', {
      headers: {
        'X-AppClientId': 872
      }
    });
  }

  /**
   * Получение списка визитов пользователя
   *
   * @returns
   */
  getUserVisits(): AsyncResponse<{ data: Visit[] }> {
    return this.client.get('/telegram/user/cars/visits', {
      headers: {
        // 'X-AppClientId': 872
      }
    });
  }

  /**
   * Получение визита по ID
   *
   * @returns
   */
  getVisitById(visitId: string): AsyncResponse<{ data: Visit[] }> {
    return this.client.get(`/telegram/user/cars/visits/${visitId}`, {
      headers: {
        // 'X-AppClientId': 872
      }
    });
  }

  /**
   * Создать визит пользователя
   *
   * @returns
   */
  createVisit(payload: CreateVisitDto): AsyncResponse<{ data: Visit }> {
    return this.client.post('/telegram/user/cars/visits', payload, {
      headers: {
        // 'X-AppClientId': 872
      }
    });
  }
}
