<script lang="ts" setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  value: {
    type: Number,
    default: 0,
  },

  color: {
    type: String,
    required: false,
  }
});

const normalizedValue = computed<number>(() => {
  return Math.min(1, Math.max(0, props.value));
});
</script>

<template>
  <ion-card class="c-card-progress">
    <div
      :style="{ '--c-card-progress-bar-value': (normalizedValue * 100) + '%' }"
      :class="{
        'c-card-progress__inner-wrap': true,
        'ion-color': props.color,
        [`ion-color-${props.color}`]: props.color,
      }"
    >
      
    </div>

    <slot></slot>
  </ion-card>
</template>

<style lang="scss">
.c-card-progress {
  position: relative;
  z-index: 0;

  &__inner-wrap {
    --c-card-progress-bar-value: 0;
    --c-card-progress-bar-color-rgb: var(--ion-color-base-rgb, var(--ion-color-primary-rgb));
    --c-card-progress-bar-color: rgba(var(--c-card-progress-bar-color-rgb), 0.2);

    background: linear-gradient(90deg, var(--c-card-progress-bar-color) var(--c-card-progress-bar-value), transparent var(--c-card-progress-bar-value));
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    pointer-events: none;
  }
}
</style>