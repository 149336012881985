<script lang="ts" setup>
import { defineProps, PropType, computed } from 'vue';
import { preparePrice, PriceFormatOptions } from '@/utils/price';

const props = defineProps({
  price: {
    type: [Number, String] as PropType<string|number>,
    required: true,
  },
  priceStrike: {
    type: [Number, String] as PropType<string|number>,
    required: false,
  },
  options: {
    type: Object as PropType<PriceFormatOptions>,
    default: () => ({}),
  },

  /** Скорректирвоать вертикальные отступы (visual-fix) */
  correctV: {
    type: Boolean,
    default: true,
  }
});

const priceFormatted = computed(() => {
  return preparePrice(props.price, {
    currency: '₽',
    ...props.options,
  });
});

const priceStrikeFormatted = computed(() => {
  return preparePrice(props.priceStrike, {
    currency: '₽',
    ...props.options,
  });
});

const hasPriceStrike = computed<boolean>(() => {
  return !!props.priceStrike; // NOTE: Теоритически должно хватать
});

</script>

<template>
  <div class="c-price">
    <div :class="{
      'c-price__inner-wrap': true,
      'correct-text-block-indents-v': props.correctV,
    }">
      {{ priceFormatted }}

      <s v-if="hasPriceStrike">
        {{ priceStrikeFormatted }}
      </s>
    </div>
  </div>
</template>

<style lang="scss">
.c-price {
  font-size: var(--c12c-text-size-lg);
  color: var(--ion-text-color);

  s {
    opacity: 0.6;
    text-decoration: none;
    position: relative;
    margin-left: 0.3em;

    &::before {
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      top: 50%;
      left: -3px;
      right: -3px;
      background: rgba(var(--ion-text-color-rgb), 0.6);
    }
  }
}
</style>