import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/services/choice'
  },
  {
    path: '/services/choice',
    name: 'services-choice',
    component: () => import ('@/views/ServicesChoice.vue')
  },
  {
    path: '/checkout/datetime',
    name: 'checkout-datetime',
    component: () => import ('@/views/CheckoutDatetime.vue')
  },
  {
    path: '/account',
    name: 'account-main',
    component: () => import ('@/views/AccountMain.vue')
  },
  {
    path: '/account/visits/:visitId',
    name: 'visit-single',
    component: () => import ('@/views/AccountVisitSingle.vue'),
    props: true,
  },
  {
    path: '/account/visits/:visitId/chat',
    name: 'visit-chat',
    component: () => import ('@/components/chat/ChatService.vue')
  },
  {
    path: '/dev/navigation', // dev
    name: 'dev-navs',
    component: () => import ('@/views/DevNavigation.vue')
  },
  {
    path: '/dev/components',
    name: 'dev-components',
    component: () => import ('@/views/DevComponents.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
