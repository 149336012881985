/**
 * Параметры форматировани цены
 */
export interface PriceFormatOptions {
  /** Всегда добавлять нули после . (default: false) */
  addZeros?: boolean;

  /** Добавить + если значение положительное (default: false) */
  addPlus?: boolean;

  /** Заменить знак . на , в качетсстве разделителя дробной части (default: false) */
  replacePoint?: boolean;

  /** Добавлять пробел в качестве разделителя тысячных (default: true) */
  putSpaces?: boolean;

  /** Символ валюты [₽] (default: '') */
  currency?: string;

  /** Отображать валюту, до значения (default: false) */
  currencyDisplayBefore?: boolean;
}

export const PRICE_FORMAT_DEFAULT_OPTIONS = {
  addZeros: false,
  addPlus: false,
  replacePoint: false,
  putSpaces: true,
  currency: '',
  currencyDisplayBefore: false,
};

/**
 * Преобразует значение в цену (в корректное число)
 * @param val значение которое будет преобразовано в число
 */
export function valueToPriceNum(val: any): number {
  if (typeof val !== 'number') {
    if (typeof val === 'boolean') return 0;

    val = Number(val);
  }

  if (Number.isFinite(val)) {
    return val;
  }

  return 0;
}

/**
 * Отформатирует число в строковое представление цены
 * 
 * @param price цена
 * @param options дополнительные параметры
 */
export function priceNumFormat(price: number, options: PriceFormatOptions = {}): string {
  const opt = { ...PRICE_FORMAT_DEFAULT_OPTIONS, ...options };

  const fd = (!opt.addZeros && Number.isInteger(price)) ? 0 : 2;
  let fPrice = price.toFixed(fd);

  if (opt.replacePoint) {
    fPrice = fPrice.replace('.', ",");
  }

  if (opt.putSpaces) {
    fPrice = fPrice.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
  }

  if (opt.addPlus) {
    fPrice = (opt.putSpaces ? '+ ' : '+') + fPrice;
  }

  if (opt.currency) {
    fPrice = (opt.currencyDisplayBefore)
      ? opt.currency + (opt.putSpaces ? ' ' : '') + fPrice
      : fPrice + (opt.putSpaces ? ' ' : '') + opt.currency;
  }

  return fPrice;
}

/**
 * Преобразует и отформатирует значение в корректное представление цены
 * 
 * @param price значение которое будет преобразовано
 * @param options дополнительные параметры форматирования
 */
export function preparePrice(price: any, options: PriceFormatOptions = {}) {
  return priceNumFormat(valueToPriceNum(price), options);
}

/**
 * Конвертирует процент в единицу (от 0.0 до 1.0)
 * 
 * @param percent 
 * @returns 
 */
export function percentToUnit(percent: any): number {
  const numberPercent = Number(percent) || 0;
  const normilizePercent = Math.min(100, Math.max(0, numberPercent));

  return normilizePercent / 100;
}