<script lang="ts" setup>
import { defineProps } from 'vue';

export interface IProps {
  defaultHref?: string;
}

const props = defineProps<IProps>();

</script>

<template>
  <ion-header class="c-app-header-clean">
    <ion-toolbar class="ion-no-padding">
      <div slot="start">
        <slot name="left">
          <ion-buttons>
            <ion-back-button
              class="c-app-header-clean__back-button"
              :default-href="props.defaultHref"
            />
          </ion-buttons>
        </slot>
      </div>

      <ion-title class="g-header-title c-app-header-clean__title">
        <slot></slot>
      </ion-title>

      <div slot="end">
        <slot name="right"></slot>
      </div>
    </ion-toolbar>
  </ion-header>
</template>

<style lang="scss">
.c-app-header-clean {
  box-shadow: none !important;

  ion-toolbar {
    --padding-start: var(--c12c-spacer);
    --padding-end: var(--c12c-spacer);
    --background: rgba(var(--ion-background-color-rgb), 1);

    &:first-child {
      --min-height: 100px;
    }

    ion-buttons {
      ion-button:last-child {
        --padding-end: 0;
      }

      ion-button:first-child {
        --padding-start: 0;
      }
    }
  }

  &__title {
    // ...
  }

  &__back-button {
    &::part(icon) {
      --icon-margin-start: 0px;
      --icon-margin-end: 10px;
      --icon-font-size: var(--c12c-text-size-lg);
    }

    // TODO: Увеличить зону клика
    // &::part(native)::after {
    //   left: calc(var(--ion-padding) * -1);
    //   right: -50px;
    //   bottom: calc(var(--ion-padding) * -1);
    // }
  }
}

// @supports (backdrop-filter: blur(30px)) {
//   .c-app-header-clean ion-toolbar {
//     --background: rgba(var(--ion-background-color-rgb), 0.85);
//     backdrop-filter: blur(20px);
//   }
// }
</style>