import { BaseRepository, AsyncResponse } from './BaseRepository';
import {ChatMessages, ChatUpdates, SendingMessage} from "@/services/repositories/models/Chat";

export class ChatRepository extends BaseRepository {
  getUserChats(): AsyncResponse<any> {
    return this.client.get('/telegram/user/chats', {
      headers: {
        'X-AppClientId': 872
      }
    });
  }

  getChatUpdates(chatId: string): AsyncResponse<ChatUpdates> {
    return this.client.get(`/telegram/user/chats/${chatId}/updates`, {
      headers: {
        'X-AppClientId': 872
      }
    });
  }

  getChatMessages(chatId: string): AsyncResponse<ChatMessages> {
    return this.client.get(`/telegram/user/chats/${chatId}/messages`, {
      headers: {
        'X-AppClientId': 872
      }
    });
  }

  sendMessage(chatId: string, payload: SendingMessage): AsyncResponse<ChatMessages> {
    return this.client.post(`/telegram/user/chats/${chatId}/messages`, payload, {
      headers: {
        'X-AppClientId': 872
      }
    });
  }
}
